import _ from 'lodash';
import Axios from 'axios';
import SHA256 from 'crypto-js/sha256';
import { BASE_API_URL, API_URL_MATERI } from './config';

Axios.defaults.withCredentials = true;

const URL = BASE_API_URL;
const URL_MATERI = API_URL_MATERI;
const STORAGE_URL = 'https://storage.impuls.id';

/**
 * General
 */
export const getCancellation = () => {
	const CancelToken = Axios.CancelToken;
	const source = CancelToken.source();
	return source;
};

/**
 * Login, Logout and validate
 */
export const check = () =>
	Axios.get([URL, 'check'].join('/'))
		.then((resp) => resp.data)
		.catch(({ response }) => _.get(response, 'data', {}));
export const checkAttr = (attr, data) =>
	Axios.post([URL, 'check', attr].join('/'), data)
		.then((resp) => resp.data)
		.catch(({ response }) => _.get(response, 'data', {}));
export const profile = () =>
	Axios.get([URL, 'profile'].join('/'))
		.then((resp) => resp.data)
		.catch(({ response }) => _.get(response, 'data', {}));
export const updateProfile = (data) =>
	Axios.put([URL, 'profile'].join('/'), data)
		.then((resp) => resp.data)
		.catch(({ response }) => _.get(response, 'data', {}));
export const changePassword = (data) => {
	const body = _.mapValues(data, (v) => SHA256(v).toString());
	return Axios.put([URL, 'password'].join('/'), body)
		.then((resp) => resp.data)
		.catch(({ response }) => _.get(response, 'data', {}));
};
export const login = (data) => {
	const body = data;
	if (!_.isEmpty(body.password)) {
		body.password = SHA256(body.password).toString();
	}
	return Axios.post([URL, 'login'].join('/'), body)
		.then((resp) => resp.data)
		.catch(({ response }) => _.get(response, 'data', {}));
};
export const logout = () =>
	Axios.post([URL, 'logout'].join('/'))
		.then((resp) => resp.data)
		.catch(({ response }) => _.get(response, 'data', {}));
export const forget = (body) =>
	Axios.post([URL, 'forget'].join('/'), body)
		.then((resp) => resp.data)
		.catch(({ response }) => _.get(response, 'data', {}));

/**
 * Base API
 */
const generateBase = (base) => ({
	index: (query) =>
		Axios.get(base, { params: query })
			.then((resp) => resp.data)
			.catch(({ response }) => _.get(response, 'data', {})),
	lite: (query) =>
		Axios.get([base, 'lite'].join('/'), { params: query })
			.then((resp) => resp.data)
			.catch(({ response }) => _.get(response, 'data', {})),
	show: (id, query) =>
		Axios.get([base, id].join('/'), { params: query })
			.then((resp) => resp.data)
			.catch(({ response }) => _.get(response, 'data', {})),
	store: (data, opts = {}) =>
		Axios.post(base, data, opts)
			.then((resp) => resp.data)
			.catch(({ response }) => _.get(response, 'data', {})),
	update: (id, data) =>
		Axios.put([base, id].join('/'), data)
			.then((resp) => resp.data)
			.catch(({ response }) => _.get(response, 'data', {})),
	updateMany: (params, data) =>
		Axios.put(base, data, { params })
			.then((resp) => resp.data)
			.catch(({ response }) => _.get(response, 'data', {})),
	remove: (id) =>
		Axios.delete([base, id].join('/'))
			.then((resp) => resp.data)
			.catch(({ response }) => _.get(response, 'data', {})),
	removeMany: (params) =>
		Axios.delete([base].join('/'), { params })
			.then((resp) => resp.data)
			.catch(({ response }) => _.get(response, 'data', {})),

	getImageURI: (fname) => ['' + URL, 'public/image', fname].join('/'),
});

/**
 * Asset Resource
 */
export const asset = (() => {
	const base = [URL, 'asset'].join('/');
	return _.assign({}, generateBase('' + base), {
		download: (id) =>
			Axios.get([base, id].join('/'), { responseType: 'arraybuffer' }).catch(
				(error) => error.response,
			),
		getURL: (path) => [STORAGE_URL, path].join('/'),
	});
})();

/**
 * Karyawan Resource
 */
export const karyawan = (() => {
	const base = [URL, 'karyawan'].join('/');
	return _.assign({}, generateBase('' + base), {
		indexSistem: (query) =>
			Axios.get([base, 'sistem'].join('/'), { params: query })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		changePassword: (id, data) =>
			Axios.put([base, id, 'reset-password'].join('/'), data)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
	});
})();

/**
 * Mata Pelajaran Resource
 */
export const mataPelajaran = (() => {
	const base = [URL, 'mata-pelajaran'].join('/');
	return _.assign({}, generateBase('' + base), {});
})();

/**
 * Murid Resource
 */
export const murid = (() => {
	const base = [URL, 'murid'].join('/');
	return _.assign({}, generateBase('' + base), {
		indexJadwal: () =>
			Axios.get([base, 'jadwal'].join('/'))
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		query: (params) =>
			Axios.get([base, 'query'].join('/'), { params })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		changePassword: (id, data) =>
			Axios.put([base, id, 'reset-password'].join('/'), data)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		changeKelas: (id, data) =>
			Axios.put([base, id, 'pindah-kelas'].join('/'), data)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		download: (data) =>
			Axios.post([base, 'unduh'].join('/'), data)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		deactivate: (data) =>
			Axios.post([base, 'nonaktif'].join('/'), data)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		showSesi: (sesi_id) =>
			Axios.get([base, 'sesi', sesi_id].join('/'))
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		validateSesi: (data) =>
			Axios.post([base, 'sesi', 'cek'].join('/'), data)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		showSesiStatus: (sesi_id) =>
			Axios.get([base, 'sesi', sesi_id, 'status'].join('/'))
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		storeLembarJawab: (data) =>
			Axios.post([base, 'lembar-jawab'].join('/'), data)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		showLembarJawab: (sesi_id) =>
			Axios.get([base, 'lembar-jawab', sesi_id].join('/'))
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		updateLembarJawab: (lembar_jawab_id, data, params) =>
			Axios.put(
				[base, 'lembar-jawab', lembar_jawab_id].join('/'),
				{ ...data, timestamp: new Date() },
				{
					params,
				},
			)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		showHasilSesi: (sesi_id) =>
			Axios.get([base, 'sesi', sesi_id, 'hasil'].join('/'))
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		withoutKelas: () =>
			Axios.get([base, 'tanpa-kelas'].join('/'))
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		indexHasil: () =>
			Axios.get([base, 'hasil'].join('/'))
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
	});
})();

/**
 * Paket Soal Resource
 */
export const paketSoal = (() => {
	const base = [URL, 'paket-soal'].join('/');
	return _.assign({}, generateBase('' + base), {
		updateSoal: (id, data) =>
			Axios.put([base, id, 'soal'].join('/'), data)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
	});
})();

/**
 * sekolah Resource
 */
export const sekolah = (() => {
	const base = [URL, 'sekolah'].join('/');
	return _.assign({}, generateBase('' + base), {
		showPenggunaan: (query) =>
			Axios.get([base, 'statistik'].join('/'), { params: query })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
	});
})();
/**
 * kelompok Resource
 */
export const kelompok = (() => {
	const base = [URL, 'kelompok'].join('/');
	return _.assign({}, generateBase('' + base), {});
})();

/**
 * tahunAjaran Resource
 */
export const tahunAjaran = (() => {
	const base = [URL, 'tahun-ajaran'].join('/');
	return _.assign({}, generateBase('' + base), {
		downloadDKN: (id, params) =>
			Axios.get([base, id, 'unduh-dkn'].join('/'), {
				params,
				responseType: 'arraybuffer',
			}).catch((error) => error.response),
		migrate: (data) =>
			Axios.patch([base, 'pindah'].join('/'), data)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		showLite: (id, query) =>
			Axios.get([base, id, 'lite'].join('/'), { params: query })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
	});
})();

/**
 * jadwal Resource
 */
export const jadwal = (() => {
	const base = [URL, 'jadwal'].join('/');
	return _.assign({}, generateBase('' + base), {
		updateNilai: (id, data) =>
			Axios.put([base, id, 'nilai'].join('/'), data)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		showSesi: (id, query) =>
			Axios.get([base, 'sesi', id].join('/'), { params: query })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		showStatistikSesi: (id, query) =>
			Axios.get([base, 'sesi', id, 'statistik'].join('/'), { params: query })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		getDurasiSesi: (id, query) =>
			Axios.get([base, 'sesi', id, 'durasi'].join('/'), { params: query })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		updateSesi: (id, data) =>
			Axios.put([base, 'sesi', id].join('/'), data)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		updateStatusSesi: (id, status) =>
			Axios.put([base, 'sesi', id, status].join('/'))
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		removeSesi: (id) =>
			Axios.delete([base, 'sesi', id].join('/'))
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		header: (query) =>
			Axios.get([base, 'header'].join('/'), { params: query })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		updateStatusLembarJawab: (lembar_jawab_id, data, status) =>
			Axios.put([base, 'lembar-jawab', lembar_jawab_id, status].join('/'), data)
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		indexHasil: (query) =>
			Axios.get([base, 'hasil'].join('/'), { params: query })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		showHasil: (jadwal_id, query) =>
			Axios.get([base, jadwal_id, 'hasil'].join('/'), { params: query })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		showMonitor: (jadwal_id, query) =>
			Axios.get([base, jadwal_id, 'monitor'].join('/'), { params: query })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		showHasilPeserta: (lembar_jawab_id, query) =>
			Axios.get([base, 'hasil', lembar_jawab_id].join('/'), { params: query })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		sendSessionInvitation: (id) =>
			Axios.post([base, 'sesi', id, 'undang'].join('/'))
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		buildABS: (jadwal_id, query) =>
			Axios.get([base, jadwal_id, 'analisis-soal'].join('/'), { params: query })
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
		rebuildLembarJawab: (id) =>
			Axios.post([base, 'lembar-jawab', id, 'susun-ulang'].join('/'))
				.then((resp) => resp.data)
				.catch(({ response }) => _.get(response, 'data', {})),
	});
})();

/**
 * kelas Resource
 */
export const kelas = (() => {
	const base = [URL, 'kelas'].join('/');
	return _.assign({}, generateBase('' + base), {});
})();

/**
 * role Resource
 */
export const role = (() => {
	const base = [URL, 'role'].join('/');
	return _.assign({}, generateBase('' + base), {});
})();

/**
 * materi belajar
 */
export const materi = (() => {
	const base = [URL_MATERI, 'materi'].join('/');

	async function index(token, idTahunAjaran) {
		return Axios.get(`${base}?idTahunAjaran=${idTahunAjaran}`, {
			headers: { Authorization: `Bearer ${token}` },
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	async function show(token, id) {
		return Axios.get(`${base}/${id}`, {
			headers: { Authorization: `Bearer ${token}` },
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	async function post(token, data) {
		return Axios.post(base, data, {
			headers: { Authorization: `Bearer ${token}` },
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	async function update(token, id, data) {
		return Axios.patch(`${base}/${id}`, data, {
			headers: { Authorization: `Bearer ${token}` },
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	async function remove(token, id) {
		return Axios.delete(`${base}/${id}`, {
			headers: { Authorization: `Bearer ${token}` },
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	async function indexByMapel(token, idTahunAjaran) {
		return Axios.get(`${base}/mapel?idTahunAjaran=${idTahunAjaran}`, {
			headers: { Authorization: `Bearer ${token}` },
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	async function showByMapel(token, idMapel) {
		return Axios.get(`${base}/mapel/${idMapel}`, {
			headers: { Authorization: `Bearer ${token}` },
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	async function indexMurid(token, materiId) {
		return Axios.get(`${base}/${materiId}/murid`, {
			headers: { Authorization: `Bearer ${token}` },
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

  async function indexKomentar(token, id) {
    return Axios.get(`${base}/${id}/komentar`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => data)
      .catch((error) => error);
  }

  async function storeKomentar(token, id, data) {
    return Axios.post(`${base}/${id}/komentar`, data, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => data)
      .catch((error) => error);
  }

  async function removeKomentar(token, id, komentarId) {
    return Axios.delete(`${base}/${id}/komentar/${komentarId}`, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => data)
      .catch((error) => error);
  }

	async function storeTugas(token, idMateri, idTugas, data) {
    return Axios.post(`${base}/${idMateri}/tugas/${idTugas}`, data, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => data)
      .catch((error) => error);
  }

	async function nilaiTugas(token, idMateri, data) {
    return Axios.post(`${base}/${idMateri}/tugas`, data, { headers: { Authorization: `Bearer ${token}` } })
      .then(({ data }) => data)
      .catch((error) => error);
  }

  return {
    index,
    show,
    post,
    update,
    remove,
    indexByMapel,
    showByMapel,
    indexMurid,
    indexKomentar,
    storeKomentar,
    removeKomentar,
		storeTugas,
		nilaiTugas
  }
})();

export const topik = (() => {
	const base = [URL_MATERI, 'topik'].join('/');

	async function index(token) {
		return Axios.get(base, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	return { index };
})();

export const materiAsset = (() => {
	const base = [URL_MATERI, 'asset'].join('/');

	async function store(token, id) {
		return Axios.post(base, id, {
			headers: { Authorization: `Bearer ${token}` },
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	async function show(token, nama) {
		return Axios.get(`${base}/${nama}`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	async function showThumbnail(token, nama) {
		return Axios.get(`${base}/thumbnail-${nama}`, {
			headers: { Authorization: `Bearer ${token}` },
			responseType: 'blob',
		})
			.then(({ data }) => data)
			.catch((error) => ({ isError: true }));
	}

	return {
		store,
		show,
		showThumbnail,
	};
})();

export const sekolahMateri = (() => {
	const base = [URL_MATERI, 'sekolah'].join('/');

	async function showPenggunaan(token, tahunAjaranId) {
		const url = tahunAjaranId
			? `${base}/statistik?tahun_ajaran_id=${tahunAjaranId}`
			: `${base}/statistik`;
		return Axios.get(url, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	return { showPenggunaan };
})();

export const jadwalMateri = (() => {
	const base = [URL_MATERI, 'jadwal'].join('/');

	async function showStatistikSesi(token, sesiId) {
		return Axios.get(`${base}/sesi/${sesiId}/statistik`, {
			headers: { Authorization: `Bearer ${token}` },
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	return { showStatistikSesi };
})();

export const muridMateri = (() => {
	const base = [URL_MATERI, 'murid'].join('/');

	async function storeDevice(token, lembarJawabId) {		
		return Axios.post(`${base}/sesi/${lembarJawabId}/perangkat`, {}, {
			headers: { Authorization: `Bearer ${token}` },
		})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	return { storeDevice };
})();

export const materiDokumen = (() => {
	const base = [URL_MATERI, 'dokumen'].join('/');

	async function dokumenIndex(token) {
		return Axios.get(base, { headers: { Authorization: `Bearer ${token}` }})
			.then(({ data }) => data)
			.catch((error) => error);
	}

	return {
		dokumenIndex,
	};
})();

export default {
	getCancellation,

	check,
	login,
	logout,
	profile,

	role,
	asset,
	kelompok,
	jadwal,
	karyawan,
	kelas,
	murid,
	paketSoal,
	sekolah,
	tahunAjaran,
};
