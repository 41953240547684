// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { FBASE_API_KEY, FBASE_DATABASE_URL } from './helpers/constant';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: FBASE_API_KEY,
	// authDomain: "halo-cfe95.firebaseapp.com",
	databaseURL: FBASE_DATABASE_URL,
	// projectId: "halo-cfe95",
	// storageBucket: "halo-cfe95.appspot.com",
	// messagingSenderId: "356644891452",
	// appId: "1:356644891452:web:f164d85538ae8d7a970c39",
	// measurementId: "G-TVB02V9L75"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
