export const BASE_API_URL = 'https://api.icbt.impuls.run';
// export const BASE_API_URL = 'https://v1.api.icbt.impuls.id';

export const API_URL_MATERI = 'https://materi.api.icbt.impuls.run';

export const SEKOLAH_KODE = null;
export const SEKOLAH_NAMA = null;
export const SEKOLAH_LOGO =
	'https://storage.googleapis.com/storage.impuls.id/icbt/asset/default-logo.png';

export const BACKGROUND =
	'https://storage.googleapis.com/storage.impuls.id/icbt/asset/default-bg.jpg';
export const BACKGROUND_MINI =
	'https://storage.googleapis.com/storage.impuls.id/icbt/asset/default-bg-mini.jpg';
