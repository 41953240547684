import _ from 'lodash';
import Media from 'react-media';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
	Image,
	Header,
	Menu,
	Dropdown,
	Button,
	Icon,
	Label,
} from 'semantic-ui-react';

import pp_karyawan from '../../assets/pp_karyawan.png';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context';
import { SEKOLAH_LOGO } from '../../config';

const propTypes = {
	listMenu: PropTypes.arrayOf(
		PropTypes.shape({
			nama: PropTypes.string,
			type: PropTypes.string,
			value: PropTypes.string,
			options: PropTypes.arrayOf(
				PropTypes.shape({
					key: PropTypes.string,
					text: PropTypes.string,
					value: PropTypes.string,
					icon: PropTypes.string,
				}),
			),
		}),
	),
	onActionChange: PropTypes.func,
	onActionClick: PropTypes.func,
	logoSekolah: PropTypes.string,
	name: PropTypes.string,
	roleUser: PropTypes.string,
};

const defaultProps = {
	listMenu: [],
	name: '',
	roleUser: '',
};

class LogoSekolah extends Component {
	shouldComponentUpdate(np) {
		const { src } = this.props;
		return src !== np.src;
	}

	render() {
		const { src } = this.props;
		return (
			<div style={{ height: '3.2em' }}>
				<Image
					style={{ height: '3.2em' }}
					src={src || SEKOLAH_LOGO}
				/>
			</div>
		);
	}
}

const icbt = 'Impuls Computer Based Test (ICBT)';

class HeaderMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: '',
			width_menu: 0,
			namaSekolah: '',
		};
		this.WidthChange = 0;

		this.toggleHoverEnter = this.toggleHoverEnter.bind(this);
		this.toggleHoverLeave = this.toggleHoverLeave.bind(this);
		this.updateVH = this.updateVH.bind(this);
	}

	toggleHoverEnter(e) {
		const element = e.target;
		const id = element.getAttribute('id');
		this.setState({ hover: id });
	}
	toggleHoverLeave(e) {
		this.setState({ hover: '' });
	}

	updateVH() {
		const { width_menu } = this.state;
		if (document.documentElement.clientWidth !== width_menu) {
			const width_change = document.documentElement.clientWidth;
			return this.setState({ width_menu: width_change });
		}
	}

	/**
	 * React LifeCycle
	 */
	componentDidMount() {
		this.updateVH();
		// window.addEventListener('scroll', this.updateVH);
		// window.addEventListener('resize', this.updateVH);
		this.WidthChange = setInterval(this.updateVH, 200);
		const namaSekolah = _.get(this.context, 'sekolah.nama', '');
		this.setState({ namaSekolah });
	}
	componentWillUnmount() {
		clearInterval(this.WidthChange);
	}

	render() {
		const { hover, width_menu, namaSekolah } = this.state;
		const {
			onActionChange,
			onActionClick,
			logoSekolah = '',
			listMenu = [],
			name = '',
			active,
			roleUser,
		} = this.props;

		let nama = '';

		if (`${name}`.length > 17) {
			nama = `${name.slice(0, 17)}...`;
		} else {
			nama = name;
		}

		return (
			<div
				style={{
					backgroundColor: '#EBF8F0',
					display: 'grid',
					gridTemplateColumns: 'auto auto',
					width: width_menu,
				}}>
				<div>
					<Menu
						secondary
						style={{ height: '100%' }}>
						<Media query={{ maxWidth: 710 }}>
							{(v) =>
								v ? (
									<Menu.Item style={{ marginRight: 0 }}>
										<div style={{ display: 'flex', alignItems: 'flex-end' }}>
											{!_.isEmpty(logoSekolah) && (
												<LogoSekolah src={logoSekolah} />
											)}
											<Media query={{ maxWidth: 1075 }}>
												{(v) =>
													!v && (
														<div
															style={{
																marginLeft: '1em',
																alignSelf: 'center',
																display: 'flex',
															}}>
															<Header
																style={{ alignSelf: 'center' }}
																as="h3">
																{namaSekolah}
																<Header.Subheader>{icbt}</Header.Subheader>
															</Header>
														</div>
													)
												}
											</Media>
										</div>
									</Menu.Item>
								) : (
									<Menu.Item position="left">
										<div style={{ display: 'flex', alignItems: 'flex-end' }}>
											{!_.isEmpty(logoSekolah) && (
												<LogoSekolah src={logoSekolah} />
											)}
											<Media query={{ maxWidth: 1075 }}>
												{(v) =>
													!v && (
														<div
															style={{
																marginLeft: '1em',
																alignSelf: 'center',
																display: 'flex',
															}}>
															<Header
																style={{ alignSelf: 'center' }}
																as="h3">
																{namaSekolah}
																<Header.Subheader>{icbt}</Header.Subheader>
															</Header>
														</div>
													)
												}
											</Media>
										</div>
									</Menu.Item>
								)
							}
						</Media>
						<Media query={{ maxWidth: 710 }}>
							{(v) =>
								v
									? !_.isEmpty(listMenu) && (
											<Menu.Item style={{ marginLeft: 0, padding: '.2em' }}>
												<div style={{ color: '#00B5AD' }}>
													<Dropdown
														trigger={
															<span>
																<b>ICBT</b>
															</span>
														}>
														<Dropdown.Menu>
															{listMenu.map((o, i) => {
																if (
																	o.type !== 'dropdown' ||
																	o.nama === 'Jadwal Ujian' ||
																	o.nama === 'Bank Soal'
																) {
																	return (
																		<Dropdown.Item
																			key={`${o.nama}+i${i}`}
																			value={o.value}
																			onClick={onActionClick}>
																			<div style={{ color: '#00B5AD' }}>
																				{o.nama}
																			</div>
																		</Dropdown.Item>
																	);
																}
																return (
																	<Dropdown.Item key={`${o.nama}+r${i}`}>
																		<div style={{ color: '#00B5AD' }}>
																			<Dropdown
																				text={o.nama}
																				onChange={onActionChange}>
																				<Dropdown.Menu>
																					{(o.options || []).map((oo, ii) => (
																						<Dropdown.Item
																							key={`${oo.value}++${ii}`}
																							as={Link}
																							to={oo.value}
																							value={oo.value}
																							onClick={onActionClick}>
																							<div style={{ color: '#00B5AD' }}>
																								{oo.icon && (
																									<Icon name={oo.icon} />
																								)}
																								{oo.text}
																							</div>
																						</Dropdown.Item>
																					))}
																				</Dropdown.Menu>
																			</Dropdown>
																		</div>
																	</Dropdown.Item>
																);
															})}
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</Menu.Item>
										)
									: listMenu.map((o, i) => {
											if (
												_.isEqual(active, o.nama) ||
												_.isEqual(hover, o.nama)
											) {
												// new feature
												if (o.nama === 'Materi Belajar') {
													return (
														<Menu.Item
															key={`${o.nama}+${i}`}
															as="button"
															id={o.nama}
															onMouseEnter={this.toggleHoverEnter}
															onMouseLeave={this.toggleHoverLeave}>
															<Button.Group
																size="small"
																color="teal">
																<Button
																	as={Link}
																	to={o.value}
																	{...o}
																	primary>
																	<Label
																		size="mini"
																		ribbon
																		color="orange"
																		style={{
																			position: 'absolute',
																			top: '0',
																			left: '3px',
																		}}>
																		Baru
																	</Label>
																	{o.nama}
																</Button>
																{_.isEqual('dropdown', o.type) && (
																	<Dropdown
																		style={{ borerLeftWidth: '0px' }}
																		className="button icon"
																		floating
																		onChange={onActionChange}
																		trigger={<React.Fragment />}>
																		<Dropdown.Menu>
																			{(o.options || []).map((oo) => (
																				<Dropdown.Item
																					as={Link}
																					to={oo.value}
																					{..._.omitBy(oo, ['text', 'icon'])}>
																					<div style={{ color: '#00B5AD' }}>
																						{oo.icon && <Icon name={oo.icon} />}
																						{oo.text}
																					</div>
																				</Dropdown.Item>
																			))}
																		</Dropdown.Menu>
																	</Dropdown>
																)}
															</Button.Group>
														</Menu.Item>
													);
												}
												return (
													<Menu.Item
														key={`${o.nama}+${i}`}
														as="button"
														id={o.nama}
														onMouseEnter={this.toggleHoverEnter}
														onMouseLeave={this.toggleHoverLeave}>
														<Button.Group
															size="small"
															color="teal">
															<Button
																as={Link}
																to={o.value}
																{...o}
																primary>
																{o.nama}
															</Button>
															{_.isEqual('dropdown', o.type) && (
																<Dropdown
																	style={{ borerLeftWidth: '0px' }}
																	className="button icon"
																	floating
																	onChange={onActionChange}
																	trigger={<React.Fragment />}>
																	<Dropdown.Menu>
																		{(o.options || []).map((oo) => (
																			<Dropdown.Item
																				as={Link}
																				to={oo.value}
																				{..._.omitBy(oo, ['text', 'icon'])}>
																				<div style={{ color: '#00B5AD' }}>
																					{oo.icon && <Icon name={oo.icon} />}
																					{oo.text}
																				</div>
																			</Dropdown.Item>
																		))}
																	</Dropdown.Menu>
																</Dropdown>
															)}
														</Button.Group>
													</Menu.Item>
												);
											} else {
												// new feature
												if (o.nama === 'Materi Belajar') {
													return (
														<Menu.Item
															key={`${o.nama}+lk${i}`}
															as="button"
															id={o.nama}
															onMouseEnter={this.toggleHoverEnter}
															onMouseLeave={this.toggleHoverLeave}>
															{_.isEqual(o.type, 'dropdown') && (
																<div
																	style={{
																		display: 'flex',
																		alignContent: 'center',
																	}}>
																	<div>
																		<Header
																			color="teal"
																			as="h5"
																			id={o.nama}
																			onMouseEnter={this.toggleHoverEnter}
																			onMouseLeave={this.toggleHoverLeave}>
																			{o.nama}
																		</Header>
																	</div>
																	<div>
																		<Icon
																			size="small"
																			color="teal"
																			name="dropdown"
																		/>
																	</div>
																</div>
															)}
															{!_.isEqual(o.type, 'dropdown') && (
																<Header
																	color="teal"
																	as="h5"
																	id={o.nama}
																	onMouseEnter={this.toggleHoverEnter}
																	onMouseLeave={this.toggleHoverLeave}>
																	<Label
																		size="mini"
																		ribbon
																		color="orange"
																		style={{
																			position: 'absolute',
																			top: '-6px',
																			left: '-6px',
																		}}>
																		Baru
																	</Label>
																	{o.nama}
																</Header>
															)}
														</Menu.Item>
													);
												}
												return (
													<Menu.Item
														key={`${o.nama}+lk${i}`}
														as="button"
														id={o.nama}
														onMouseEnter={this.toggleHoverEnter}
														onMouseLeave={this.toggleHoverLeave}>
														{_.isEqual(o.type, 'dropdown') && (
															<div
																style={{
																	display: 'flex',
																	alignContent: 'center',
																}}>
																<div>
																	<Header
																		color="teal"
																		as="h5"
																		id={o.nama}
																		onMouseEnter={this.toggleHoverEnter}
																		onMouseLeave={this.toggleHoverLeave}>
																		{o.nama}
																	</Header>
																</div>
																<div>
																	<Icon
																		size="small"
																		color="teal"
																		name="dropdown"
																	/>
																</div>
															</div>
														)}
														{!_.isEqual(o.type, 'dropdown') && (
															<Header
																color="teal"
																as="h5"
																id={o.nama}
																onMouseEnter={this.toggleHoverEnter}
																onMouseLeave={this.toggleHoverLeave}>
																{o.nama}
															</Header>
														)}
													</Menu.Item>
												);
											}
										})
							}
						</Media>
					</Menu>
				</div>
				<div
					style={{
						padding: '0.929em',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
					}}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ height: '2.9em', width: '2.9em' }}>
							<Image
								circular
								style={{
									position: 'absolute',
									width: '2.9em',
									clip: 'rect(0, 2.9em, 2.9em, 0)',
								}}
								src={pp_karyawan}
							/>
						</div>
						<div
							style={{
								marginLeft: '.5em',
								display: 'flex',
								flexDirection: 'column',
							}}>
							<div style={{ color: '#00B5AD' }}>
								<Dropdown
									direction="left"
									name="profil-menu"
									trigger={
										<Media query={{ maxWidth: 800 }}>
											{(v) =>
												!v && (
													<span>
														<strong>{nama}</strong>
													</span>
												)
											}
										</Media>
									}>
									<Dropdown.Menu>
										<Dropdown.Item
											as={Link}
											to="/profil"
											style={{ textDecoration: 'none' }}>
											<div style={{ color: '#00B5AD' }}>Pengaturan Profil</div>
										</Dropdown.Item>
										<Dropdown.Item
											onClick={onActionClick}
											value="logout">
											<div style={{ color: '#00B5AD' }}>
												<Icon name="log out" />
												Keluar/ Logout
											</div>
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<Media query={{ maxWidth: 800 }}>
								{(v) => !v && <div>{roleUser}</div>}
							</Media>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

HeaderMenu.contextType = UserContext;
HeaderMenu.propTypes = propTypes;
HeaderMenu.defaultProps = defaultProps;
export default HeaderMenu;
