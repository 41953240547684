import React, { Component } from 'react';
import PropTypes from 'prop-types';
import logo_impuls from '../../assets/logo_impuls.png';

const propTypes = {
	widthImage: PropTypes.string,
	widthContainer: PropTypes.number,
	paddingContainer: PropTypes.string,
	fontColor: PropTypes.string,
	fontSize: PropTypes.string,
};

const defaultProps = {
	widthImage: '43',
	widthContainer: 290,
	paddingContainer: '1em',
	fontColor: 'black',
	fontSize: '.8rem',
};

class Footer extends Component {
	render() {
		const {
			widthImage,
			widthContainer,
			paddingContainer,
			fontColor,
			fontSize,
		} = this.props;

		const content = {
			left: (
				<img
					src={logo_impuls}
					width={widthImage || '43'}
					alt="Sistem Informasi Sekolah | Impuls ID"
				/>
			),
			right: (
				<p
					style={{
						fontSize: fontSize || '.8rem',
						lineHeight: '1.5em',
						color: fontColor || 'black',
					}}>
					Copyright © {new Date().getFullYear()}
					<br />
					PT. Impuls Digital Indonesia
					<br />
					All Right Reserved
				</p>
			),
		};

		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start',
					width: widthContainer || 290,
					padding: paddingContainer || '1em',
				}}>
				<div
					style={{ marginRight: '1em', display: 'flex', alignItems: 'center' }}>
					{content.left}
				</div>
				<div>{content.right}</div>
			</div>
		);
	}
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
