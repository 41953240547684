export default [
	/**
	 * ================
	 * KARYAWAN
	 * ================
	 */
	{ id: 'JADWAL', type: 'dropdown', menu: 'Jadwal Ujian', to: '/jadwal' },
	{
		parent: 'JADWAL',
		id: 'JADWAL#DJADWAL',
		menu: 'Daftar Jadwal Ujian',
		to: '/jadwal',
	},
	{
		parent: 'JADWAL',
		id: 'JADWAL#DJADWAL#MONITOR',
		menu: 'Monitor Sesi Ujian Aktif',
		icon: 'eye',
		to: '/jadwal/monitor-sesi',
	},
	{
		parent: 'JADWAL',
		id: 'JADWAL#DJADWAL#BARU',
		menu: 'Jadwal Ujian Baru',
		icon: 'calendar plus',
		to: '/jadwal/baru',
	},

	{ id: 'BANKSOAL', type: 'dropdown', menu: 'Bank Soal', to: '/bank-soal' },
	{
		parent: 'BANKSOAL',
		id: 'BANKSOAL#DPAKET',
		menu: 'Daftar Bank Soal',
		to: '/bank-soal',
	},
	{
		parent: 'BANKSOAL',
		id: 'BANKSOAL#DPAKET#BARU',
		menu: 'Paket Soal Baru',
		icon: 'book',
		to: '/bank-soal/baru',
	},

	// { id: "HASIL", type:"dropdown", menu: "Nilai Ujian", to: "/hasil-ujian" },
	{ id: 'HASIL', type: 'button', menu: 'Nilai Ujian', to: '/hasil-ujian' },
	{
		parent: 'HASIL',
		id: 'HASIL#DHASIL',
		menu: 'Nilai Ujian',
		to: '/hasil-ujian',
	},
	// { parent: "HASIL", id: "HASIL#DHASILPAKET", menu: "Hasil Paket Soal", to: "/hasil-ujian/paket" },

	{
		id: 'MATERI',
		type: 'button',
		menu: 'Materi Belajar',
		to: '/materi-belajar',
	},
	{
		parent: 'MATERI',
		id: 'MATERI#DMATERI',
		menu: 'Materi Belajar',
		to: '/materi-belajar',
	},

	{
		id: 'PENGATURAN',
		type: 'dropdown',
		menu: 'Pengaturan',
		to: '/pengaturan/akun-murid',
	},
	{
		parent: 'PENGATURAN',
		id: 'SET#DMURID',
		menu: 'Akun Murid',
		to: '/pengaturan/akun-murid',
	},
	{
		parent: 'PENGATURAN',
		id: 'SET#DKARYAWAN',
		menu: 'Akun Karyawan',
		to: '/pengaturan/akun-karyawan',
	},
	{
		parent: 'PENGATURAN',
		id: 'SET#DKELAS',
		menu: 'Daftar Kelas',
		to: '/pengaturan/daftar-kelas',
	},
	{
		parent: 'PENGATURAN',
		id: 'SET#DMAPEL',
		menu: 'Daftar Mapel',
		to: '/pengaturan/daftar-mapel',
	},
	{
		parent: 'PENGATURAN',
		id: 'SET#IDENTITAS',
		menu: 'Identitas Sekolah dan Tahun Ajaran',
		to: '/pengaturan/identitas-sekolah',
	},
	// { parent: "PENGATURAN", id: "SET#UMUM", menu: "Statistik Penggunaan", icon: "chart bar", to: "/pengaturan/statistik-penggunaan" },

	// { id: "KELAS", logo: "imp imp-kelompok", menu: "Kelas & Kelompok" },
	// { parent: "KELAS", id: "KELAS#DKELAS_KELAS", menu: "Daftar Kelas", to: "/kelas/daftar-kelas" },

	// { id: "HASIL#DHASIL", type: "button", menu: "Nilai Hasil", to: "/hasil-ujian" },
	//========================================================================

	/**
	 * ================
	 * MURID
	 * ================
	 */

	{ id: 'IKHTISAR', logo: 'imp imp-ikhtisar', menu: 'Ikhtisar' },
	{
		parent: 'IKHTISAR',
		id: 'IKHTISAR#UMUM',
		menu: 'Ikhtisar Umum',
		to: '/ikhtisar/umum',
	},

	{ id: 'UJIAN', type: 'button', menu: 'Sesi Ujian', to: '/ujian' },
	{ parent: 'UJIAN', id: 'UJIAN#DUJIAN', menu: 'Sesi Ujian', to: '/ujian' },

	{ id: 'BELAJAR', type: 'button', menu: 'Materi Belajar', to: '/mapel' },
	{
		parent: 'BELAJAR',
		id: 'BELAJAR#DMAPEL',
		menu: 'Materi Belajar',
		to: '/mapel',
	},
	//========================================================================
];
