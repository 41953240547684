import _ from 'lodash';
import React, { Component } from 'react';
import { Form, Icon, Message, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { UserContext } from '../../context';
import { forget } from '../../fetcher';

// import { login } from '../../fetcher';
// import { UserContext } from '../../context';
// import { FORGET_FORM } from '../../helpers/constant';

const PopupContentContainer = styled.div`
	display: inline-block;
	position: absolute;
	top: -0.4em;
`;

class ForgetForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formStatus: false,
			formValue: {},
			data: {},
			success: false,
			isLoading: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e, props) {
		const { formValue } = this.state;
		const { name, value } = props;
		this.setState({ formStatus: false });
		return this.setState({
			formValue: _.assign({}, formValue, { [name]: value }),
		});
	}

	async handleSubmit() {
		const { formValue } = this.state;
		const { setStatus, kode_sekolah, setStatusKode } = this.props;
		this.setState({ isLoading: true });
		setStatusKode({ ..._.cloneDeep(this.props.formStatusKode) }, true);
		const response = await forget({ ...formValue, sekolah_kode: kode_sekolah });

		let formStatus = false;
		let formStatusKode = false;

		if (response.status_code === 200) {
			await setStatusKode(formStatusKode, false);
			setStatus('FORGET_HELP');
			return this.setState({ success: true, isLoading: false });
		} else {
			formStatus = { status: 'error', props: {} };
			formStatusKode = { status: 'error', props: {} };
			switch (response.message) {
				case 'User: Document not found': {
					formStatus.props = {
						negative: true,
						size: 'tiny',
						header: 'Login Gagal',
						content:
							'Kode sekolah tidak ditemukan, tanyakan kepada admin sistem sekolah/ lembaga.',
					};
					formStatusKode = {};
					break;
				}
				case 'Sekolah: Document not found': {
					formStatusKode.props = {
						negative: true,
						size: 'tiny',
						header: 'Kode Sekolah/ Lembaga Tidak Ditemukan',
						content:
							'Anda dapat menanyakan kode tersebut kepada Administrator masing-masing sekolah/ lembaga',
					};
					formStatus = {};
					break;
				}
				default:
			}
		}

		await setStatusKode(formStatusKode, false);

		this.setState({ isLoading: false, formStatus, formStatusKode });
	}

	validateEmail = (email) => {
		let regex = /^\w+[+.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i;
		return regex.test(email);
	};

	render() {
		const { formValue, formStatus, success, isLoading } = this.state;
		const { setStatus, kode_sekolah } = this.props;
		const email = _.get(formValue, 'email', '');
		const disabledButton =
			!this.validateEmail(email) || _.isEmpty(kode_sekolah);

		return (
			<Form>
				<Form.Field>
					{!success && (
						<span style={{ color: 'black' }}>
							Masukan alamat surel Anda dan kami mengirimkan pranala bantuan
							untuk menyetel ulang password Anda
						</span>
					)}
					{success && (
						<span style={{ color: 'black' }}>
							Surel reset password sudah kami kirim. Cek di kotak masuk surel
							kamu!
						</span>
					)}
				</Form.Field>
				{!success && (
					<Form.Field
						style={{ position: 'relative' }}
						required>
						<label
							style={{ display: 'inline-block', marginRight: '.4em' }}
							className="teal">
							Alamat Surel/ Email
						</label>
						{
							<Popup
								wide
								size="tiny"
								horizontalOffset={10}
								trigger={
									<PopupContentContainer>
										<Icon
											circular
											inverted
											size="mini"
											name="info"
											color="orange"
										/>
									</PopupContentContainer>
								}>
								Jika Anda belum pernah mendaftarkan surel Anda, hubungi Tim
								Impuls Indonesia <a href="#">081228800048</a>
							</Popup>
						}
						<Form.Input
							name="email"
							disabled={isLoading}
							placeholder="contoh : info@impuls.id"
							value={email}
							onChange={this.handleChange}
						/>
					</Form.Field>
				)}
				{!_.isEmpty(formStatus) && (
					<div style={{ marginBottom: '1em' }}>
						<Message
							negative
							size="tiny">
							<Message.Header>Email tidak ditemukan</Message.Header>
							<Message.Content>
								Untuk mengetahui surel terdaftar, silakan hubungi Tim Kami di{' '}
								<span>
									<a href="https://wa.me/6281228800048">081228800048</a>
								</span>{' '}
								via WhatsApp atau SMS
							</Message.Content>
						</Message>
					</div>
				)}
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Form.Button
						style={{ margin: 0 }}
						color="teal"
						basic
						floated="right"
						disabled={isLoading}
						onClick={() => setStatus('LOGIN')}>
						<Icon name="arrow left" /> Coba Login Lagi
					</Form.Button>
					{!success && (
						<Form.Button
							color="teal"
							type="submit"
							floated="right"
							disabled={disabledButton || isLoading}
							loading={isLoading}
							onClick={this.handleSubmit}>
							Kirim
						</Form.Button>
					)}
				</div>
			</Form>
		);
	}
}

ForgetForm.contextType = UserContext;

export default ForgetForm;
